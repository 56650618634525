import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import Props from 'components/Props';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "componentName=Textarea",
        "componentName": "Textarea"
      }}>{`<Textarea
  label="Enter your long message here"
  id="top"
  placeholder="Long message"
/>
`}</code></pre>
    <h2 {...{
      "id": "props",
      "style": {
        "position": "relative"
      }
    }}>{`Props`}</h2>
    <Props identifier="Textarea" mdxType="Props" />
    <h2 {...{
      "id": "action",
      "style": {
        "position": "relative"
      }
    }}>{`Action`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Adds an action or extra information to your item, mostly used for links.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea
  id="action"
  label="Label"
  action={<LinkText href="#">LinkText</LinkText>}
/>
`}</code></pre>
    <h2 {...{
      "id": "cols",
      "style": {
        "position": "relative"
      }
    }}>{`Cols`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`This only affects the width of the textarea itself.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea id="cols" label="Label" cols={10} />
`}</code></pre>
    <h2 {...{
      "id": "disabled",
      "style": {
        "position": "relative"
      }
    }}>{`Disabled`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Marking a Textarea as `}<inlineCode parentName="p">{`disabled`}</inlineCode>{`, will disable all interaction with the element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea id="disabled" label="Label" disabled />
`}</code></pre>
    <h2 {...{
      "id": "error",
      "style": {
        "position": "relative"
      }
    }}>{`Error`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To convey an error to the user, you can set the `}<inlineCode parentName="p">{`error`}</inlineCode>{` prop to true.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea id="error" label="Label" error />
`}</code></pre>
    <h2 {...{
      "id": "labelhidden",
      "style": {
        "position": "relative"
      }
    }}>{`LabelHidden`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`To meet accessibility requirements, every TextField should have an associated
label. We do support the ability to visually hide the label when the Textarea's
purpose is clear.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea
  id="labelHidden"
  label="Label"
  value="label & id are still required!"
  labelHidden
/>
`}</code></pre>
    <h2 {...{
      "id": "maxlength",
      "style": {
        "position": "relative"
      }
    }}>{`MaxLength`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The maximum number of characters (UTF-16 code units) that the user can enter. If
this value isn't specified, the user can enter an unlimited number of
characters. Make sure you don't overrule this programmatically.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea
  id="maxLength"
  label="Label"
  value="You can't add more text in here."
  maxLength={32}
/>
`}</code></pre>
    <h2 {...{
      "id": "message",
      "style": {
        "position": "relative"
      }
    }}>{`Message`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Give additional information to the user.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea
  id="message"
  label="Label"
  message="Message can be set to provide additional information."
/>
`}</code></pre>
    <h2 {...{
      "id": "minlength",
      "style": {
        "position": "relative"
      }
    }}>{`MinLength`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`The minimum number of characters (UTF-16 code units) required that the user
should enter. Make sure you still check this programmatically.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const minLength = 38;
  const [value, setValue] = React.useState(
    "You shouldn't write less text in here."
  );
  return (
    <Textarea
      id="minLength"
      label="Label"
      minLength={minLength}
      value={value}
      onChange={e => setValue(e.target.value)}
      error={value.length < minLength}
    />
  );
};
`}</code></pre>
    <h2 {...{
      "id": "optionallabel-required-fields",
      "style": {
        "position": "relative"
      }
    }}>{`OptionalLabel (Required fields)`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`By default the Textarea is always `}<strong parentName="p">{`required`}</strong>{`. To make a Textarea optional you
can add the `}<inlineCode parentName="p">{`optionalLabel`}</inlineCode>{` prop.`}</p>
    <p>{`With Chameleon we take a specific approach where we encourage marking optional
fields, not required. The majority of fields in a form are always required.
Indicating that fields are required make users more fearful, it increases the
risk of errors and reduces the form completion rate.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea id="optionalLabel" label="Label" optionalLabel="I'm optional" />
`}</code></pre>
    <h2 {...{
      "id": "placeholder",
      "style": {
        "position": "relative"
      }
    }}>{`Placeholder`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Placeholder text is displayed inside the field but is not always necessary. They
should be written as examples helping users to understand the required input
instead of instructions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea id="placeholder" label="Label" placeholder="Placeholder" />
`}</code></pre>
    <h2 {...{
      "id": "readonly",
      "style": {
        "position": "relative"
      }
    }}>{`ReadOnly`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`It's not possible to write text inside the Textarea`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea id="readOnly" readOnly label="Label" value="You can't change me." />
`}</code></pre>
    <h2 {...{
      "id": "required",
      "style": {
        "position": "relative"
      }
    }}>{`Required`}</h2>
    <p><inlineCode parentName="p">{`default: true`}</inlineCode></p>
    <p>{`By default the Textarea is required. You can change this by providing an
optionalLabel or passing false to the required prop. We encourage you to use the
optionalLabel prop, this provides additional feedback to the user!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea id="required" required={false} label="Label" />
`}</code></pre>
    <h2 {...{
      "id": "rows",
      "style": {
        "position": "relative"
      }
    }}>{`Rows`}</h2>
    <p><inlineCode parentName="p">{`default: 2`}</inlineCode></p>
    <p>{`To increase the default height of the `}<inlineCode parentName="p">{`Textarea`}</inlineCode>{`, you can provide the number of
initial rows.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea id="rows" label="Write us a story" rows={10} />
`}</code></pre>
    <h2 {...{
      "id": "wrap",
      "style": {
        "position": "relative"
      }
    }}>{`Wrap`}</h2>
    <p><inlineCode parentName="p">{`default: soft`}</inlineCode></p>
    <p>{`Altering the `}<inlineCode parentName="p">{`wrap`}</inlineCode>{` prop, will either not wrap the inputted text (`}<inlineCode parentName="p">{`off`}</inlineCode>{`), or it
will actually wrap the inputted text. The difference between `}<inlineCode parentName="p">{`hard`}</inlineCode>{` and `}<inlineCode parentName="p">{`soft`}</inlineCode>{`
is that with `}<inlineCode parentName="p">{`hard`}</inlineCode>{`, the value submitted will include the linebreaks.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Textarea
  id="hard"
  label="Write us a story"
  wrap="hard"
  value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia lobortis erat vitae volutpat. Fusce in nunc pulvinar, rutrum nulla non, blandit nunc. Nullam orci leo, mattis in lorem quis, pharetra pellentesque tortor. Vivamus malesuada cursus lacus, nec posuere diam iaculis in. Praesent sollicitudin, tortor eget tempor malesuada, mi ex blandit ipsum, ut tincidunt urna orci bibendum massa. Maecenas ultricies justo non elementum malesuada."
/>
<Textarea
  id="soft"
  label="Write us a story"
  wrap="soft"
  value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia lobortis erat vitae volutpat. Fusce in nunc pulvinar, rutrum nulla non, blandit nunc. Nullam orci leo, mattis in lorem quis, pharetra pellentesque tortor. Vivamus malesuada cursus lacus, nec posuere diam iaculis in. Praesent sollicitudin, tortor eget tempor malesuada, mi ex blandit ipsum, ut tincidunt urna orci bibendum massa. Maecenas ultricies justo non elementum malesuada."
/>
<Textarea
  id="off"
  label="Write us a story"
  wrap="off"
  value="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia lobortis erat vitae volutpat. Fusce in nunc pulvinar, rutrum nulla non, blandit nunc. Nullam orci leo, mattis in lorem quis, pharetra pellentesque tortor. Vivamus malesuada cursus lacus, nec posuere diam iaculis in. Praesent sollicitudin, tortor eget tempor malesuada, mi ex blandit ipsum, ut tincidunt urna orci bibendum massa. Maecenas ultricies justo non elementum malesuada."
/>
`}</code></pre>
    <h2 {...{
      "id": "value",
      "style": {
        "position": "relative"
      }
    }}>{`Value`}</h2>
    <p><inlineCode parentName="p">{`default: undefined`}</inlineCode></p>
    <p>{`Use value for controlled Textfields.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js",
        "metastring": "wrap=function",
        "wrap": "function"
      }}>{`() => {
  const [value, setValue] = React.useState('The value inside.');
  return (
    <Textarea
      id="value"
      label="Label"
      value={value}
      onChange={e => setValue(e.target.value)}
    />
  );
};
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      